*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

div .root {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.card-editor form > div > input {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  border-width: 2px;
  border-radius: .375rem;
  padding: .25rem .75rem;
}

.card-editor form > div > input:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.card-editor .input-group {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  border-width: 2px;
  border-radius: .375rem;
  gap: .75rem;
  padding: .25rem .75rem;
  display: flex;
}

.card-editor .input-group:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.card-editor .input-group > input {
  text-overflow: ellipsis;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  outline-offset: 2px;
  background-color: rgba(0, 0, 0, 0);
  outline: 2px solid rgba(0, 0, 0, 0);
  flex-grow: 1;
}

.card-editor .input-group button {
  width: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.card-editor .input-group button:hover, .card-editor .input-group button:focus {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.card-editor .input-group button:active {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.card-editor label {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.card-editor .reactEasyCrop_Container {
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.text-button {
  width: 6rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  text-transform: uppercase;
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-weight: 700;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.text-button:hover, .text-button:focus {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.text-button:active {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.topbar {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, .15));
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  gap: .75rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: flex;
  position: sticky;
  top: 0;
}

.topbar button {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  text-transform: uppercase;
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
  gap: 1rem;
  padding: .5rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.topbar button:hover, .topbar button:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.topbar button:active {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.topbar button svg {
  height: 1.5rem;
  width: 1.5rem;
}

.fab {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  outline-offset: 2px;
  border-radius: 9999px;
  outline: 2px solid rgba(0, 0, 0, 0);
  padding: .75rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
}

.fab:hover, .fab:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.fab:active {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.fab svg {
  height: 1.5rem;
  width: 1.5rem;
}

.cards {
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  margin: -.5rem;
  display: flex;
}

.cards > * {
  margin: .5rem;
}

.flex-gap-4 {
  padding: .5rem;
}

.flex-gap-4 > .flex-gap-4 {
  margin: 0;
  padding: 0;
}

.flex-gap-4 > * {
  margin: .5rem;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.z-10 {
  z-index: 10;
}

.m-2 {
  margin: .5rem;
}

.m-5 {
  margin: 1.25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-12 {
  height: 3rem;
}

.h-10 {
  height: 2.5rem;
}

.h-6 {
  height: 1.5rem;
}

.min-h-full {
  min-height: 100%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-12 {
  width: 3rem;
}

.w-10 {
  width: 2.5rem;
}

.w-6 {
  width: 1.5rem;
}

.min-w-full {
  min-width: 100%;
}

.flex-grow {
  flex-grow: 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-between {
  align-content: space-between;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.justify-self-end {
  justify-self: end;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 163, 74, var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 197, 94, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 225, var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-contain {
  background-size: contain;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-10 {
  padding: 2.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-black {
  font-weight: 900;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.uppercase {
  text-transform: uppercase;
}

.leading-none {
  line-height: 1;
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(22, 163, 74, var(--tw-text-opacity));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(107, 33, 168, var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgba(71, 85, 105, var(--tw-text-opacity));
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.text-opacity-90 {
  --tw-text-opacity: .9;
}

.opacity-25 {
  opacity: .25;
}

.opacity-75 {
  opacity: .75;
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 222, 128, var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.active\:scale-95:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (min-width: 768px) {
  .md\:m-5 {
    margin: 1.25rem;
  }

  .md\:max-w-fit {
    max-width: -webkit-fit-content;
    max-width: fit-content;
  }

  .md\:whitespace-nowrap {
    white-space: nowrap;
  }
}

div.card {
  height: 25vmax;
  width: 25vmax;
  background-color: #fff;
  border-radius: 2vmax;
  flex-direction: column;
  padding: 2vmax;
  display: flex;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, .16);
}

div.card > div.picture {
  width: 18vmax;
  height: 18vmax;
  max-width: 18vmax;
  max-height: 18vmax;
  aspect-ratio: 1;
  background-size: 100% 100%;
  flex: 4;
}

div.card > div {
  font-size: x-large;
}

.letters-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.dst, .src {
  align-items: center;
  display: flex;
}

.letter {
  width: 10vmin;
  height: 10vmin;
  text-transform: capitalize;
  background-color: #fff;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  margin: 3vmin;
  font-size: 5vmin;
  display: flex;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, .16);
}

.src .letter {
  cursor: pointer;
}

.letter.error {
  animation: .3s erranim;
}

@keyframes erranim {
  to {
    color: #fff;
    background-color: #d50000;
  }
}

/*# sourceMappingURL=index.f3444aac.css.map */
