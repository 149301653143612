div.card {
    height: 25vmax;
    width: 25vmax;
    border-radius: 2vmax;
    padding: 2vmax;
    /* margin: 2vmax; */

    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);
}

div.card > div.picture{
    flex: 4;
    width: 18vmax;
    height: 18vmax;
    max-width:18vmax;
    max-height: 18vmax;
    aspect-ratio: 1;
    background-size: 100% 100%; /* width and height, can be %, px or whatever. */
}
div.card > div{
    font-size: x-large;
}