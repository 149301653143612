@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  div .root {
    @apply h-screen w-screen m-0;
  }

  .card-editor form > div > input {
    @apply bg-pink-100 rounded-md px-3 py-1 focus:outline-none border-2 focus:border-pink-500;
  }

  .card-editor .input-group {
    @apply bg-pink-100 rounded-md px-3 py-1 border-2 focus-within:border-pink-500 flex gap-3;
  }

  .card-editor .input-group > input {
    @apply outline-none bg-transparent text-gray-700 text-ellipsis flex-grow;
  }

  .card-editor .input-group button {
    @apply w-6 text-pink-600 transform transition active:scale-90 hover:scale-110 focus:scale-110 outline-none;
  }

  .card-editor label {
    @apply text-gray-400 text-sm font-bold;
  }

  .card-editor .reactEasyCrop_Container {
    @apply w-full h-full bg-black;
  }

  .text-button {
    @apply uppercase font-mono font-bold w-24 transform transition active:scale-90 hover:scale-110 focus:scale-110 outline-none;
  }

  .topbar {
    @apply sticky top-0 bg-pink-600 text-xl text-white px-3 drop-shadow-2xl flex gap-3;
  }

  .topbar button {
    @apply p-2 outline-none uppercase flex gap-4 transform transition active:scale-90 hover:bg-pink-500 focus:bg-pink-500;
  }

  .topbar button svg {
    @apply w-6 h-6;
  }

  .fab {
    @apply p-3 outline-none rounded-full fixed bottom-6 right-6  text-white hover:bg-pink-500  bg-pink-600 focus:bg-pink-500 shadow-2xl transform transition active:scale-90;
  }

  .fab svg {
    @apply w-6 h-6;
  }

  .cards {
    @apply w-full h-full flex flex-wrap space-5;
    /* background-color: whitesmoke; */
  }

  .flex-gap-4 {
    @apply p-2;
  }
  
  .flex-gap-4 > .flex-gap-4 {
    @apply p-0 m-0;
  }

  .flex-gap-4 > * {
    @apply m-2;
  }
}

@layer utilities {
  .space-5 {
    @apply -m-2;
  }

  .space-5 > * {
    @apply m-2;
  }
}
