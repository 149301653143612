.letters-container{
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    align-items: center
}
.dst, .src{
    display: flex;
    align-items: center;
}
.letter{
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);
    width: 10vmin;
    height: 10vmin;
    margin: 3vmin;
    font-size: 5vmin;
    display: flex;
    text-transform: capitalize;
    justify-content: center;
    align-items: center;
}
.src .letter{
    cursor: pointer;
}

.letter.error{
    animation: erranim 0.3s;
}

@keyframes erranim {
    to {background-color: #d50000;
        color: white;}
}